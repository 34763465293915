import React, { useState, useEffect } from 'react';
import Header from './Header'
import Footer from './Footer'
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useGetBlogByIDDataQuery } from "../redux/api/Blog";
import moment from 'moment'; 

const BlogByID = () => {
  const { id } = useParams(); 
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true); 
  const { data: blogResponse, error, isLoading } = useGetBlogByIDDataQuery(id);

  useEffect(() => {
    if (blogResponse?.data?.blog) {
      setBlog(blogResponse.data.blog);
      setLoading(false); 
    } else {
      setLoading(true); 
    }
  }, [blogResponse]);

  const getRelativeTime = (dateString) => {
    return moment(dateString).fromNow();
  };


  if (loading) {
    return <div className="text-center py-5">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-5 text-danger">Error loading blog. Please try again.</div>;
  }

  if (!blog) {
    return <div className="text-center py-5">No blog found.</div>;
  }

  return (
    <>
    <Header />
    <div className="container-fluid py-5">
  <div className="container">
    <div className="row">
      <div className="col-md-4 d-flex align-items-center">
        <img
          src={blog.image}
          alt={blog.title}
          className="img-fluid rounded shadow-sm"
          style={{ maxHeight: '500px', maxWidth: '100%' }}
        />
      </div>
      <div className="col-md-6">
        <div>
          <div className="card-body">
            <p className="text-muted small">{getRelativeTime(blog.date_added)}</p>
            <h5 className="card-title">{blog.title}</h5>
            <p className="card-text">{blog.content}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    <Footer />
  </>
  );
};

export default BlogByID;
