import { useTranslation } from "react-i18next"
import React, { useEffect, useRef } from 'react';
import Section1 from "./Home/Section1";
import Section2 from "./Home/Section2";
import Section4 from "./Home/Section4";
import Section5 from "./Home/Section5";
import Section3 from "./Home/Section3";
import Section6 from "./Home/Section6";
import Section7 from "./Home/Section7";
import Section8 from "./Home/Section8";
import Section9 from "./Home/Section9";
import FAQsBoxes from "./Home/Section10";
import Blogs from "./Home/Section11";
import Header from './Header'
import Footer from "./Footer";

const Home = () => {
  return (
        <div className="overflow-hidden">
          <Header/>
          <Section1 />

          <Section2 />

          <Section3 />

          <Section4 />

          <Section5 />

          <Section6  />

          <Section7 />

          <Section8 />
          <FAQsBoxes/>
          <Blogs/>
          {/* <Section9 /> */}
          <Footer/>
        </div>
  );
}

export default Home