import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGetBlogDataQuery } from "../../redux/api/Blog";
import moment from 'moment'; 

const Blogs = () => {
  const [allblogs, setAllBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data: AllBlogsResponse, error, isLoading } = useGetBlogDataQuery({});

  useEffect(() => {
    if (AllBlogsResponse?.data?.blogs) {
      setAllBlogs(AllBlogsResponse.data?.blogs);
      setLoading(false); 
    } else {
      setLoading(true); 
    }
  }, [AllBlogsResponse]);

  const navigate = useNavigate();

  const getRelativeTime = (dateString) => {
    return moment(dateString).fromNow();
  };

  const truncateContent = (content) => {
    const words = content.split(' ');
    return words.length > 7 ? words.slice(0, 7).join(' ') + '...' : content;
  };

  if (loading) {
    return <div className="text-center py-5">Loading...</div>;
  }

  return (
    <div className="container-fluid bg-white py-5">
      <div className="container">
        <span
          style={{
            backgroundColor: "#272657",
            color: "white",
            fontSize: "17px",
            borderRadius: "25px",
            padding: "8px 16px", 
            display: "inline-block", 
          }}
        >
          BLOG
        </span>

        <h1 className="mb-4">The Latest News, Trends, and Best Practices</h1>
        <div className="row g-4">
          {allblogs?.map((blog) => (
            <div key={blog.blog_id} className="col-12 col-md-6 col-lg-4" onClick={() => navigate(`/blog/${blog.blog_id}`)}>
              <div className="card h-100 shadow-sm border-0">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="card-img-top img-fluid"
                  style={{ height: '200px', objectFit: 'cover' }}
                />
                <div className="card-body">
                  <p className="text-muted small">{getRelativeTime(blog.date_added)}</p> 
                  <h5 className="card-title">{blog.title}</h5>
                  <p className="card-text">{truncateContent(blog.content)}</p> {/* Truncate content to first 7 words */}

                  <Link to={`/blog/${blog.blog_id}`} className="btn btn-sm" style={{backgroundColor:"#4A2394", textDecoration: 'none', color:"white"}}>
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
