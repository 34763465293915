import React from 'react'
import { FaLinkedin,FaTiktok, FaInstagram, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="mt-auto container-fluid border-top border-opacity-50 bg-white">
    <div className="container-xl">
      <footer>
        <div className="row justify-content-between py-5">
          {/* Company Info Section */}
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <div className="d-flex flex-column gap-3">
              <a href="/">
                <img 
                  src="/logo.png" 
                  alt="CloudgoUp" 
                  className="img-fluid rounded" 
                  style={{ width: '120px' }} 
                />
              </a>
              <p className="text-muted" style={{ lineHeight: '1.6' }}>
                At Cloud Go Up, our commitment is to empower you with effortless 
                creation of exceptional digital solutions that are efficient and 
                of the highest quality. Our team of experts collaborates seamlessly 
                with cutting-edge AI-powered tools to help you produce top-notch 
                websites, mobile apps, and digital strategies.
              </p>
            </div>
          </div>
  
          {/* Quick Links Section */}
          <div className="col-6 col-md-3 col-lg-2 mb-4">
            <h5 style={{color:"#5E1AB4" ,fontWeight:"bold"}}>Quick Links</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="/" className="nav-link p-0 text-muted">Home</Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/about" className="nav-link p-0 text-muted">About Us</Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/portfolio" className="nav-link p-0 text-muted">Portfolio</Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/services" className="nav-link p-0 text-muted">Our Services</Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/contact" className="nav-link p-0 text-muted">Let's Talk</Link>
              </li>
            </ul>
          </div>
  
          {/* Social Media Links Section */}
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column align-items-start">
            <h5 className="mb-3" style={{color:"#5E1AB4" ,fontWeight:"bold"}}>Follow Us</h5>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div className="bottom-inner row clearfix">
    <div className="col-lg-4 col-md-12 col-sm-12">
      <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ position: 'relative' }}>
          <Link
            to="https://www.facebook.com/cloudgoup"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'black',
              position: 'relative',
            }}
            aria-label="Facebook"
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#1877f2';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.borderColor = 'white';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
          <FaFacebook size={20}/>
            
          </Link>
        </div>
        <div style={{ position: 'relative' }}>
          <Link
            to="https://www.linkedin.com/company/cloudgoup"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="Linkedin"
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#1da1f2';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.borderColor = 'white';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
             <FaLinkedin size={20}/>
            
          </Link>
        </div>
        <div style={{ position: 'relative' }}>
          <Link
            to=" https://www.tiktok.com/@cloudgoup"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="TikTok"
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'black';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.borderColor = 'white';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
             <FaTiktok size={20}/>
          
          </Link>
        </div>
        <div style={{ position: 'relative' }}>
          <Link
            to="https://www.instagram.com/cloudgoup/"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="Instagram"
            onMouseEnter={(e) => {
            e.currentTarget.style.background = '#dd2a7b';
              e.currentTarget.style.color = 'white';
             e.currentTarget.style.background = '#dd2a7b';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
              <FaInstagram size={20}/>
          </Link>
        </div>

        <div style={{ position: 'relative' }}>
          <Link
            to="https://wa.me/96181130833"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="WhatsApp"
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#25d366';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.borderColor = 'white';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
            <FaWhatsapp size={20}/>
          </Link>
        </div>
      </div>
    </div>
    </div>
    </div>
          </div>
        </div>
  
        {/* Footer Bottom Section */}
        <div className="text-center py-4 border-top">
          <small className="text-muted">
            © 2024 CloudgoUp, All rights reserved.
          </small>
        </div>
      </footer>
    </div>
  </div>
  
  )
}

export default Footer