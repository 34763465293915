import React, { useEffect, useState } from "react";
import { useGetFAQCategoriesQuery } from "../../redux/api/FAQs";

const FAQsBoxes = () => {
  const [allcategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data: AllCategoriesResponse, error, isLoading } = useGetFAQCategoriesQuery({});

  useEffect(() => {
    if (AllCategoriesResponse?.data) {
      setAllCategories(AllCategoriesResponse.data);
      setLoading(false); 
    } else {
      setLoading(true); 
    }
  }, [AllCategoriesResponse]);



  return (
    <div className="container my-5">
      <span
        style={{
          backgroundColor: "#272657",
          color: "white",
          fontSize: "17px",
          borderRadius: "25px",
          padding: "8px 16px",
          display: "inline-block",
        }}
      >
        SUPPORT
      </span>

      <h1 className="mb-4">Have a Question?</h1>
      <div className="accordion" id="faqAccordion">
        {allcategories?.map((faq, index) => (
          <div className="accordion-item mb-3" key={faq.faq_id}>
            <h2 className="accordion-header" id={`heading${faq.faq_id}`}>
              <button
                className={`accordion-button shadow-sm ${faq.faq_id === "96" ? "" : "collapsed"}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${faq.faq_id}`}
                aria-expanded={faq.faq_id === "96" ? "true" : "false"}
                aria-controls={`collapse${faq.faq_id}`}
                style={{
                  color: faq.faq_id === "96" ? "black" : "inherit",
                  backgroundColor: faq.faq_id === "96" ? "white" : "inherit",
                  boxShadow: faq.faq_id === "96"
                    ? "inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)"
                    : "none",
                }}
              >
                {faq.question}
              </button>
            </h2>
            <div
              id={`collapse${faq.faq_id}`}
              className={`accordion-collapse collapse shadow-sm ${faq.faq_id === "96" ? "show" : ""}`}
              aria-labelledby={`heading${faq.faq_id}`}
              data-bs-parent="#faqAccordion"
            >
              <div
                className="accordion-body"
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQsBoxes;
