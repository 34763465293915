import { Link } from 'react-router-dom';

const Section6 = () => {
  return (
        <div className="container-fluid bg-white py-5 section6">
            <div className="row container-xl mx-auto text-center">
                <h3 className="m-0">Looking for Digital solutions?</h3>
                <Link to="/contact" className="fs-5 ms-2" style={{textDecoration:"none",marginTop:"10px"}}><span style={{fontSize:"30px" ,color:"#4A2394"}}>Contact us</span></Link>
            </div>
            <style>{`
                .section6 h3{
                    line-height: 1em;
                    letter-spacing: -0.045em;
                    color: #272657;
                    font-weight: 600;
                }
                .section6 a{
                    color: #64607d !important;
                    font-weight: 500;
                }
                .section6 a:hover{
                    color: #403d4e !important;
                }
            `}</style>
        </div>
    )
}

export default Section6