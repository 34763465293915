import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, useTexture } from '@react-three/drei';
import { TextureLoader } from 'three';

const RotatingCube = () => {
  const cubeRef = useRef();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const textureUrls = [
    'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg',
    'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg',
    'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg',
    'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-original-wordmark.svg',
    'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg',
    'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg',
  ];

  const textures = textureUrls.map((url) => new TextureLoader().load(url));

  useFrame(() => {
    if (cubeRef.current) {
      cubeRef.current.rotation.x += 0.01;
      cubeRef.current.rotation.y += 0.01;
    }
  });

  return (
    <mesh ref={cubeRef}>
      <boxGeometry args={[2, 2, 2]} />
      {textures.map((texture, index) => (
        <meshStandardMaterial key={index} attach={`material-${index}`} map={texture} />
      ))}
    </mesh>
  );
};

const HeroSection = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    heroButton: {
      padding: '1rem 1rem',
      backgroundColor: 'white',
      color: '#6A0DAD',
      borderRadius: '8px',
      fontWeight: 'bold',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
  };

  return (
    <div>
      {/* Hero Section */}
      <section>
        <div
          style={{
            display: 'flex',
            flexDirection: isLargeScreen ? 'row' : 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '2rem',
            overflow:"hidden",
            transform: isLargeScreen ? 'translateX(-8%)' : '',
          }}
        >
          {/* Canvas Section */}
          <Canvas
            style={{
              width: isLargeScreen ? '50%' : '100%',
              height: isLargeScreen ? '400px' : '300px',
            }}
          >
            <ambientLight intensity={2} />
            <directionalLight position={[5, 5, 5]} intensity={1} />
            <RotatingCube />
            <OrbitControls enableZoom={isLargeScreen?true:false} />
          </Canvas>

          {/* Text Section */}
          <div
            style={{
              flex: 1,
              padding: '1rem',
              textAlign: 'center',
              maxWidth: '500px',
              transform: isLargeScreen ? 'translateX(-20%)' : '',
            }}
          >
            <h1 style={{ fontWeight: 'bold', fontSize: isLargeScreen ? '2.5rem' : '1.8rem' }}>
              Innovate Your World
            </h1>
            <p style={{ fontSize: isLargeScreen ? '1.2rem' : '1rem', marginBottom: '1rem' }}>
              Crafting software solutions with 3D Design that drive success and make a difference.
            </p>
            <button
              style={styles.heroButton}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#32CD32',e.target.style.color = 'white')}
              onMouseOut={(e) => (e.target.style.backgroundColor = 'white',e.target.style.color = '#6A0DAD')}
            >
              Get Started
            </button>
          </div>

          {/* Image Section */}
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              padding: '1rem',
            }}
          id="img">
            <img
              src="/laptop.png"
              alt="laptop"
              style={{
                width: isLargeScreen ? '70%' : '100%',
                height: 'auto',
              }}
            />
          </div>
        </div>
      </section>
      <style>{`
  /* Container Animation */
  #img {
    display: inline-block;
    animation: float 5s ease-in-out infinite, slideIn 1s ease-out both;
  }

  #img img {
    margin-top: 20px;
    width: 100% !important;
    animation: scaleUp 2s ease-in-out infinite, pulse 1.5s ease-in-out infinite alternate;
  }

  /* Floating animation for smooth motion */
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* Gentle slide-in effect */
  @keyframes slideIn {
    0% {
      transform: translateY(-30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* Scaling animation */
  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  /* Pulsating effect on hover */
  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(0.95);
    }
  }

  /* Responsive design adjustments */
  @media screen and (max-width: 768px) {
    #img img {
      animation: scaleUp 2s ease-in-out infinite, pulse 2s ease-in-out infinite alternate;
    }
  }
`}</style>
    </div>
    
  );
};

export default HeroSection;
