import { FaAngleRight } from "react-icons/fa6";
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Section1 = () => {
    const [t, i18n] = useTranslation("global");
    const colors = [
        '#97D2B5', '#685BA8', '#6E3F99',
      ];
    
      const generateRandomNumber = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min) + min);
      };
    
      useEffect(() => {
        const particles_svg = document.querySelectorAll('.particle-svg');
        particles_svg.forEach(particle => {
          const randomIndex = generateRandomNumber(0, colors.length);
          const color = colors[randomIndex];
          const size = generateRandomNumber(5, 30);
          const randomX = generateRandomNumber(0, 100);
          const randomY = generateRandomNumber(0, 100);
          (particle as HTMLElement).style.width = `${size}px`;
          (particle as HTMLElement).style.height = `${size}px`;
          (particle as HTMLElement).style.transform = `translate3d(${randomX}vw, ${randomY}vh, 0)`;
          (particle as HTMLElement).style.animation = `move ${generateRandomNumber(20, 30)}s linear infinite alternate`;
        });

        const particles = document.querySelectorAll('.particle');
        particles.forEach(particle => {
          const randomIndex = generateRandomNumber(0, colors.length);
          const color = colors[randomIndex];
          const size = generateRandomNumber(5, 15); // Smaller size
          const randomX = generateRandomNumber(0, 50);
          const randomY = generateRandomNumber(0, 50);
    
          (particle as HTMLElement).style.borderColor = color;
          (particle as HTMLElement).style.width = `${size}px`;
          (particle as HTMLElement).style.height = `${size}px`;
          (particle as HTMLElement).style.transform = `translate3d(${randomX}vw, ${randomY}vh, 0)`;
    
          (particle as HTMLElement).style.animation = `move ${generateRandomNumber(25, 30)}s linear infinite`;
          (particle as HTMLElement).style.background = color;
        });

      }, []);

  return (
    <div className='container-xl px-4 px-md-0 position-relative section1'>
        {Array.from({ length: 12 }).map((_, index) => (
            <svg key={index} className="particle-svg" xmlns="http://www.w3.org/2000/svg" fill="none" height="12" viewBox="0 0 23 12" width="23"><path d="m14.6249 7.93745c-3.2417 1.28714-6.23276-5.46672-6.93801-.23591-.23436 2.37966-2.26361 4.37246-4.7288 3.31446-.82668-.362-1.85561-.9951-2.167241-1.89026-.36799-.99907.941231-1.91264 1.765911-1.26355.42566.34839.88645.74146 1.37839.942 2.12482.77442.55622-4.22283 3.78153-5.35614 1.29408-.51679 2.97762.15825 3.99262.992.3592.17655 1.9294 1.67972 2.2543 1.09544.3592-.70157.5623-1.51895.7863-2.29134.5249-1.66304 2.4298-2.49921 4.0025-1.76055.3704.16777.6883.4021 1.024.60619.121.07114.2735.03073.3462-.08602.0151-.02449.0244-.0517.0295-.0774.1582-.72968.5069-1.327292 1.4133-1.208643 1.8086.424963.8669 3.067963-.3237 3.700333-.8889.46211-1.8171.32264-2.7586-.27974-.3097-.18479-.6282-.56688-.9977-.50591-.9286-.03431-.4172 3.3998-2.8605 4.30504z" fill="#eb9aa0"/></svg>
        ))}
        {Array.from({ length: 12 }).map((_, index) => (
            <div key={index} className="particle"></div>
        ))}

        <div className="row py-5">
            <div className="col-md-6 row1-left d-flex flex-column justify-content-center gap-4">
                <div>
                    <span>{t("home.section1.left.span1")}</span><br></br>
                    <span>{t("home.section1.left.span2")}</span><br></br>
                    <span>{t("home.section1.left.span3")}</span>
                </div>
                <div>
                    <p>{t("home.section1.left.p")}</p>
                </div>
                <div><Link type="button" to="/about" className="rounded-5" style={{backgroundColor:"#4A2394",fontSize:'16px'}}>{t("home.section1.left.btn")} <FaAngleRight /></Link></div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center row1-right">
                <img src="/laptop.png" alt="laptop" className="img-fluid" /> 
            </div>
            <div className="col-12 text-center speech">
                <img src="/speech.png" alt="laptop" className="img-fluid" /> 
            </div>
        </div>
        <style>{`
          .section1 .row1-left span{
            line-height: 1em;
            letter-spacing: -0.045em;
            font-size: 70px;
            color: #272657;
            font-weight: 600;
            -webkit-animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        
          }
          .tes
          }
          .section1 .row1-left p{
            color: #64607d;
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
          }
          .section1 .row1-left a{
            font-family: "Wix Madefor Text", Sans-serif;
            font-size: 15px;
            font-weight: 500;
            line-height: 1em;
            padding: 18px 30px 18px 30px;
            margin: 0;
            background: #1d2840;
            text-decoration: none;
            color: #ffffff !important;
            box-shadow: none;
            transition: all 0.65s cubic-bezier(0.23, 1, 0.32, 1);
          }
          .section1 .row1-left a:hover{
            background: #141c2c;
            transform: scale(1.05);
          }
          .section1 .row1-right img{
            width: 500px !important;
            animation: bounce 1s infinite alternate;
          }
          .section1 .row1-right{
            -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: bounce 1s infinite alternate, slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          }
          @keyframes bounce {
            from {
              transform: translateY(0);
            }
            to {
              transform: translateY(-40px);
            }
          }
          @-webkit-keyframes pulsate-bck {
            0% {
              -webkit-transform: scale(1);
                      transform: scale(1);
            }
            50% {
              -webkit-transform: scale(0.9);
                      transform: scale(0.9);
            }
            100% {
              -webkit-transform: scale(1);
                      transform: scale(1);
            }
          }
          @keyframes pulsate-bck {
            0% {
              -webkit-transform: scale(1);
                      transform: scale(1);
            }
            50% {
              -webkit-transform: scale(0.9);
                      transform: scale(0.9);
            }
            100% {
              -webkit-transform: scale(1);
                      transform: scale(1);
            }
          }
          /* @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-20px);
            }
            60% {
                transform: translateY(-5px);
            }
          } */
          
          .speech img{
            width: 150px !important;
            -webkit-animation: pulsate-bck 1s ease-in-out infinite both;
            animation: pulsate-bck 1s ease-in-out infinite both;
            /* animation: bounce2 3s infinite; */
          }
          @keyframes bounce2 {
            0%, 50%, 80%, 100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-10px);
            }
            90% {
                transform: translateY(-5px);
            }
          }
          
          .particle1{
            animation: fly 5s linear infinite;
          }
          @keyframes fly {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(100vw, -100vh, 100vh);
            }
          }
        
          .particle-svg {
            position: absolute;
            top: 0;
            border: 0;
            border-radius: 50%;
            opacity: 0.9;
            transform-origin: top;
          }
          .particle {
            position: absolute;
            top: 0;
            left: 0;
            border: 2px solid;
            border-radius: 50%;
            opacity: 0.8;
          }
          @keyframes move {
            100% {
              transform: translate3d(50vw, 50vh, 0);
            }
          }
        
          @-webkit-keyframes tracking-in-expand-fwd-top {
            0% {
              letter-spacing: -0.5em;
              -webkit-transform: translateZ(-700px) translateY(-500px);
                      transform: translateZ(-700px) translateY(-500px);
              opacity: 0;
            }
            40% {
              opacity: 0.6;
            }
            100% {
              -webkit-transform: translateZ(0) translateY(0);
                      transform: translateZ(0) translateY(0);
              opacity: 1;
            }
          }
          @keyframes tracking-in-expand-fwd-top {
            0% {
              letter-spacing: -0.5em;
              -webkit-transform: translateZ(-700px) translateY(-500px);
                      transform: translateZ(-700px) translateY(-500px);
              opacity: 0;
            }
            40% {
              opacity: 0.6;
            }
            100% {
              -webkit-transform: translateZ(0) translateY(0);
                      transform: translateZ(0) translateY(0);
              opacity: 1;
            }
          }
          
          @-webkit-keyframes slide-in-fwd-center {
            0% {
              -webkit-transform: translateZ(-1400px);
                      transform: translateZ(-1400px);
              opacity: 0;
            }
            100% {
              -webkit-transform: translateZ(0);
                      transform: translateZ(0);
              opacity: 1;
            }
          }
          @keyframes slide-in-fwd-center {
            0% {
              -webkit-transform: translateZ(-1400px);
                      transform: translateZ(-1400px);
              opacity: 0;
            }
            100% {
              -webkit-transform: translateZ(0);
                      transform: translateZ(0);
              opacity: 1;
            }
          }  
        `}</style>
    </div>
  )
}

export default Section1