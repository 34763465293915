import React from 'react'
import Header from './Header'
import Footer from './Footer'

const AboutUs = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
        <Header />
        <div className="container py-5">
      <h2
        className="text-center text-uppercase fw-bold mb-3"
        style={{
          letterSpacing: "2px",
          textShadow: "2px 2px 5px rgba(0,0,0,0.1)",
          color: "#6F3CE8"
        }}
      >
        Making Digital Solutions Accessible to Everyone
      </h2>
      <h4 className="text-center text-muted mb-4">
        Discover the benefits of digital solutions and how they can elevate your business
      </h4>

      <div className="px-4 py-4  rounded shadow-sm">
        <h5 className="fw-semibold mb-3" style={{color: "#6F3CE8"}}>Who Are We</h5>
        <h4 className="fw-bold text-dark mb-2">
          Discover the best digital solution for your business
        </h4>
        <p className="text-muted">
          Our team will equip you with everything you need for your business to thrive online.
          Whether it's a website, a mobile application, e-commerce integration for your business,
          or a customized IT & Enterprise solution to help boost your productivity.
        </p>

        <h4 className="fw-bold text-dark mt-4">Become a digital entrepreneur</h4>
        <p className="text-muted">
          There are business owners with an entrepreneurial spirit that want to take advantage of
          the online landscape but they might not have the know-how, staff, or resources required
          to successfully tap into the online market.
        </p>
        <p className="text-muted">
          <span className="fw-bold">Cloud Go Up</span> sees an opportunity to help these ambitious
          business owners lay down the blueprint for a future where even the smallest businesses
          can take advantage of the cloud without paying a lot of overhead costs, building a
          digital infrastructure, or even having to hire new employees.
        </p>

        <h4 className="fw-bold text-dark mt-4">Let's start talking</h4>
        <p className="text-muted">
          Do you want to find out how digital solutions can elevate your business? Do you want to
          start boosting your sales? Do you want a cost-effective digital solution that is optimized
          for your business?
        </p>
        <p className="text-muted">
          <span className="fw-bold" style={{color: "#6F3CE8"}}>Talk to us</span> and we can start putting together
          a digital solution that can elevate your business with the latest in digital technology.
        </p>
      </div>

      <h5 className="text-center text-dark mt-5">
        Still have a question? Send an email to{" "}
        <a
          href="mailto:info@cloudgoup.com"
          className="text-decoration-none"
          style={{color: "#6F3CE8"}}
        >
          info@cloudgoup.com
        </a>
      </h5>
    </div>
        <Footer />
    </div>
  )
}

export default AboutUs 