import { apiSlice } from './apiSlice';

export const BlogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlogData: builder.query({
    query: () => ({
      url: `/blogs`,
      method: 'GET',
    }),
    providesTags: (result,error) => [{ type: 'BlogData'}],
  }),
  getBlogByIDData: builder.query({
    query: (id) => ({
      url: `/blog/${id}`,
      method: 'GET',
    }),
    providesTags: (result,error) => [{ type: 'BlogData'}],
  }),
  }),
});


export const {useGetBlogDataQuery ,useGetBlogByIDDataQuery} = BlogApi;
