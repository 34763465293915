
import Cookies from 'js-cookie';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";

const Testimonial = () => {
    const [t, i18n] = useTranslation("global");

    const lang = Cookies.get('language') || 'en';
    const dir = Cookies.get('direction') || 'ltr';

    const rtl = (dir == 'rtl');

    const testimonials = [
        {
          "id": 1,
          "icon": "https://img.icons8.com/ios/50/000000/user-male.png", // Example of man icon
          "name": "John Doe",
          "role": "CEO at Tech Solutions",
          "rating": 5,
          "feedback": "The service was exceptional, professional, and timely. Highly recommended!"
        },
        {
          "id": 2,
          "icon": "https://img.icons8.com/ios/50/000000/user-female.png", // Example of woman icon
          "name": "Jane Smith",
          "role": "Marketing Director",
          "rating": 4,
          "feedback": "Our website's performance has improved drastically after their work. Great team!"
        },
        {
          "id": 3,
          "icon": "https://img.icons8.com/ios/50/000000/user-male.png",
          "name": "Mark Spencer",
          "role": "Founder at Creative Minds",
          "rating": 5,
          "feedback": "A fantastic experience from start to finish. The team delivered beyond expectations."
        },
        {
          "id": 4,
          "icon": "https://img.icons8.com/ios/50/000000/user-female.png",
          "name": "Lisa Carter",
          "role": "Manager at Tech Minds",
          "rating": 4,
          "feedback": "Professional and communicative at every stage. The team ensured everything was smooth."
        },
        {
          "id": 5,
          "icon": "https://img.icons8.com/ios/50/000000/user-male.png",
          "name": "Robert Wilson",
          "role": "CTO at NextGen Systems",
          "rating": 5,
          "feedback": "The website redesign transformed our customer experience. We couldn't be happier!"
        }
      ];
      
    
    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            y: 200
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, delay: 0.6 }
        }
    }

    const owlCarouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Initialize Owl Carousel
        $(owlCarouselRef.current!).owlCarousel({
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000, 
            margin: 30,
            nav: true,
            dotsEach: true,
            stagePadding: 0,
            // autoplaySpeed: 3000,
            // slideTransition: 'linear',
            // fluidSpeed: false,
            // navText: ['&#8592;', '&#8594;'],
            // center: true,
            autoplayHoverPause: true,
            rtl: rtl,
            dots:true,
            items: 20,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                800:{
                    items:3
                },
                1000:{
                    items:3
                }
            }
        /* Owl Carousel options */
        });

        // Cleanup on unmount
        return () => {
        $(owlCarouselRef.current!).trigger('destroy.owl.carousel');
        };
    }, [rtl]);

  return (
    <motion.div variants={fadeInAnimationVariants} initial="initial" whileInView={fadeInAnimationVariants.animate} viewport={{once:true}} className="container-fluid py-5">
        <div className="container-xl mx-auto row3">
            <div className="row1">
            <h3 style={{ backgroundColor: "#272657",color:"white" }}>
  Testimonials
</h3>

            </div>
            <div className="row row2 my-5">
                <div className="col-md-6 col-sm-8 mb-4 mb-sm-0"><span className="">What Our Clients Say</span></div>
            </div>
        </div>
        <div className="row3 d-flex justify-content-center">
            <div ref={owlCarouselRef} className="owl-carousel owl-theme col-xl-10 col-md-10 row3">
                {testimonials.map((item:any, index) => (
                    <div key={index} className="item" dir={dir}>
                        <div className="bg-white d-flex justify-content-center flex-column gap-3 card-solutions p-4 m-3">
                         <h3>{item.name}</h3>
                         <div style={{
  display: 'flex',
  alignItems: 'center',
}}>
  {Array.from({ length: 5 }, (_, index) => (
    <p
      key={index}
      style={{
        color: index < item.rating ? 'gold' : 'gray',
        fontSize: '20px',
      }}
    >
      ★
    </p>
  ))}
</div>



                            <div><h3>{item.role}</h3></div>
                            <div><p>{item.feedback}</p></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </motion.div>
  )
}

export default Testimonial