import React from "react";
import { motion } from "framer-motion";

const Section5 = () => {
  const fadeInAnimationVariants = {
    initial: {
      opacity: 0,
      // y: 200
      x: -200,
    },
    animate: {
      opacity: 1,
      // y: 0,
      x: 0,
      transition: { duration: 1, delay: 0.2 },
    },
  };
  return (
    <motion.div
      variants={fadeInAnimationVariants}
      initial="initial"
      whileInView={fadeInAnimationVariants.animate}
      viewport={{ once: true }}
      className="container-fluid py-5"
    >
      <div className="container-xl mx-auto section5">
        <div className="row1">
        <h3 style={{ backgroundColor: "#272657",color:"white" }}>
 WHO WE ARE?
</h3>
        </div>
        <div className="row align-items-center row2">
          <div className="col-xl-6 d-flex justify-content-center left">
            <img
              src="/4-e1714380233514.png"
              alt="laptop"
              className="img-fluid"
            />
          </div>
          <div className="col-xl-6 right">
            <div>
              <h3>
                Cloud Go Up is your trusted partner for innovative digital
                solutions.
              </h3>
            </div>
            <div className="mt-3">
              <p>
                With a focus on creativity, expertise, and customer
                satisfaction, we deliver tailored services to help businesses
                thrive in the digital age. Our team of professionals is
                dedicated to providing top-notch web development, mobile app
                creation, web design, e-commerce solutions, hosting, domains,
                SEO, and digital marketing services. Partner with us to elevate
                your online presence and achieve your digital goals effectively.
              </p>
            </div>
          </div>
        </div>
      </div>
      <style>{`
                .section5 .row1 h3{
                    width: fit-content;
                    font-size: 15px;
                    text-transform: uppercase;
                    padding: 12px 20px 12px 20px;
                    background-color: #F8FAE7;
                    border-radius: 99px 99px 99px 99px;
                    color: #272657;
                    font-weight: 600;
                    box-shadow: 0px 2px 1px 0px rgba(75.00000000000001, 99.00000000000007, 147, 0.14901960784313725);
                  }
                .section5 .row2 .right h3{
                    line-height: 1em;
                    letter-spacing: -0.045em;
                    font-size: 4rem;
                    color: #272657;
                    font-weight: 600;
                  }
                .section5 .row2 .right p{
                    color: #64607d;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 30px;
                  }
                .section5 .row2 .left img{
                    width: 450px;
                    animation: bounce 1s infinite alternate;
                  }
                @media screen and (max-width: 550px) {
                    .section5 .row2 .left img{
                      width: 250px;
                    }
                    .section5 .row2 .right h3{
                      font-size: 3rem;
                    }
                  }
            `}</style>
    </motion.div>
  );
};

export default Section5;
