import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import ContactImage from '../assets/contact.png';
import axios from "axios";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  business: string;
  description: string;
}

interface Errors {
  firstName?: string;
  lastName?: string;
  email?: string;
  business?: string;
  description?: string;
}

const Contact: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    business: '',
    description: '',
  });
  const isLargeScreen = window.innerWidth >= 1024; 

  const [errors, setErrors] = useState<Errors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = (): Errors => {
    const newErrors: Errors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First Name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last Name is required';
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = 'Valid Email Address is required';
    if (!formData.business.trim()) newErrors.business = 'Business name is required';
    if (!formData.description.trim())
      newErrors.description = 'Business description is required';
    return newErrors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    const validationErrors = validate();
    setErrors(validationErrors);
  
    // If there are validation errors, stop the submission process
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
  
    const dataToSend = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      business: formData.business,
      description: formData.description,
    };
  
    try {
      await axios.post("https://ecommerce.cloudgoup.com/contact:5001/send-email", dataToSend);
      alert( "Email sent successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        business: "",
        description: "",
      });
      setErrors({});
    } catch (error) {
      console.error("Error sending email:", error);
      alert( "An error occurred while sending the email." );
    }
  };
  

  return (
    <div className="d-flex flex-column min-vh-100 bg-white text-dark">
  <Header />
  <div className="container text-center py-5">
    <img
      src={ContactImage}
      alt="Contact Us"
      className="img-fluid mb-4"
      style={{ maxWidth: "300px" }}
    />
    <h3 style={{color:"#5E1AB4"}}>WORK WITH US</h3>
    <h1 className="fw-bold mb-4">Let's get started</h1>
    <p className="mb-5">
      Book a consultation with us so we can start working on an online package
      that is tailored to your business.
    </p>
    <form
  onSubmit={handleSubmit}
  className="border shadow p-4"
  style={{
    borderWidth: "25px",
    borderColor: "#ccc",
  }}
>
  <div className="row mb-3">
    <div className="col-md-6">
      <label className="form-label d-block text-start" htmlFor="firstName">
        First Name
      </label>
      <input
        type="text"
        className={`form-control ${
          errors.firstName ? "is-invalid" : ""
        }`}
        id="firstName"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        placeholder="Enter your first name here"
      />
      {errors.firstName && (
        <div className="invalid-feedback">{errors.firstName}</div>
      )}
    </div>
    <div className="col-md-6">
      <label className="form-label d-block text-start" htmlFor="lastName" style={{marginTop:isLargeScreen?"0px":"15px"}}>
        Last Name
      </label>
      <input
        type="text"
        className={`form-control ${
          errors.lastName ? "is-invalid" : ""
        }`}
        id="lastName"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        placeholder="Enter your last name here"
      />
      {errors.lastName && (
        <div className="invalid-feedback">{errors.lastName}</div>
      )}
    </div>
  </div>
  <div className="row mb-3">
    <div className="col-md-6">
      <label className="form-label d-block text-start" htmlFor="email" >
        Email Address
      </label>
      <input
        type="email"
        className={`form-control ${errors.email ? "is-invalid" : ""}`}
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Example: user@website.com"
      />
      {errors.email && (
        <div className="invalid-feedback">{errors.email}</div>
      )}
    </div>
    <div className="col-md-6">
      <label className="form-label d-block text-start" htmlFor="business" style={{marginTop:isLargeScreen?"0px":"15px"}}>
        Business Name
      </label>
      <input
        type="text"
        className={`form-control ${errors.business ? "is-invalid" : ""}`}
        id="business"
        name="business"
        value={formData.business}
        onChange={handleChange}
        placeholder="Your business's name goes here"
      />
      {errors.business && (
        <div className="invalid-feedback">{errors.business}</div>
      )}
    </div>
  </div>
  <div className="mb-3">
    <label className="form-label d-block text-start" htmlFor="description" style={{marginTop:isLargeScreen?"0px":"15px"}}>
      Business Description
    </label>
    <textarea
      className={`form-control ${
        errors.description ? "is-invalid" : ""
      }`}
      id="description"
      name="description"
      rows={4}
      value={formData.description}
      onChange={handleChange}
      placeholder="What is your business and why do you need to go online?"
    />
    {errors.description && (
      <div className="invalid-feedback">{errors.description}</div>
    )}
  </div>
  <button
    type="submit"
    className="btn btn-primary w-100"
    style={{
      backgroundColor: "#5E1AB4",
      color: "white",
      marginTop:isLargeScreen?"0px":"5px"
    }}
  >
    Get The Consultation
  </button>
</form>

  </div>
  <Footer />
</div>

    
  );
};

export default Contact;
