import React, { useRef ,useState,useEffect} from 'react';
import HeroSection from './Portfolio/HeroSection';
import Header from './Header';
import Footer from './Footer';
import Section2 from './Portfolio/Section2';
import Testimonial from './Portfolio/Testimonial';

  
const Portfolio = () => {

   

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      background: 'linear-gradient(to bottom, #6A0DAD, #32CD32)',
      color: 'white',
      fontFamily: 'Arial, sans-serif',
    },
    section: {
      padding: '4rem 1.5rem',
      textAlign: 'center',
    },
    hero: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '6rem 1.5rem',
    },
    heroButton: {
      padding: '1rem 1rem',
      backgroundColor: 'white',
      color: '#6A0DAD',
      borderRadius: '8px',
      fontWeight: 'bold',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
    servicesGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '1.5rem',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    card: {
      backgroundColor: '#E9D8FD',
      color: '#6A0DAD',
      padding: '2rem',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.3s ease',
    },
    portfolioGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '1.5rem',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    testimonial: {
      backgroundColor: '#E9D8FD',
      color: '#6A0DAD',
      padding: '1.5rem',
      borderRadius: '8px',
      maxWidth: '350px',
      margin: '0 auto',
    },
    form: {
      maxWidth: '600px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    input: {
      padding: '1rem',
      borderRadius: '8px',
      border: '2px solid #6A0DAD',
      outline: 'none',
    },
    submitButton: {
      padding: '1rem 2rem',
      backgroundColor: 'white',
      color: '#6A0DAD',
      borderRadius: '8px',
      fontWeight: 'bold',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
  };

  return (
    <div style={styles.container}>
      {/* Header */}
      <Header/>
     
      <HeroSection/>
      <Section2/>

      {/* Portfolio Section */}
      <section style={styles.section}>
        <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '2rem' }}>Our Work</h2>
        <div style={styles.portfolioGrid}>
          {[1, 2, 3].map((item) => (
            <div key={item} style={styles.card}>
              <img
                src={`https://via.placeholder.com/400?text=Project+${item}`}
                alt={`Project ${item}`}
                style={{ width: '100%', borderRadius: '8px', marginBottom: '1rem' }}
              />
              <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Project {item}</h3>
              <p>A brief description of the project and its impact.</p>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials */}
     <Testimonial/>

      
      {/* Footer */}
      <Footer />
     
  

    </div>

  );
};

export default Portfolio;
