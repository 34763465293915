import { apiSlice } from './apiSlice';

export const FAQApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubCategoryFAQs: builder.query({
      query: (id) => ({
        url: `/faqcategory/category/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [{ type: 'faq_id', id }, { type: 'faq', id: 'LIST' }],
    }),
    getFAQCategories: builder.query({
      query: () => ({
        url: `/faqs `,
        method: 'GET',
      }),
      providesTags: (result, error,) => [{ type: 'faq', id: 'LIST' }],
    }),
    getFAQById: builder.query({
      query: (faq_id) => ({
        url: `faqcategory/faq/${faq_id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { faq_id }) => [{ type: 'faq_id', faq_id }, { type: 'faq', id: 'LIST' }],
    }),
    getFAQContactUs: builder.query({
      query: () => ({
        url: `faqcategory/getContactUsImage`,
        method: 'GET',
      }),
    }),
  }),
});

// Export hooks for the injected endpoints
export const { useGetSubCategoryFAQsQuery,useGetFAQCategoriesQuery,useGetFAQByIdQuery ,useGetFAQContactUsQuery} = FAQApi;
