import React, { useEffect, useState } from 'react';
import Phone from '../../assets/phone.png';
import VideoSrc from '../../assets/video.mp4'


const Section2 = () => {
  
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

    useEffect(() => {
      const handleResize = () => {
        setIsLargeScreen(window.innerWidth >= 1024);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <div className="d-flex align-items-center justify-content-center flex-wrap gap-3">

    <div className="text-left" style={{overflow:'hidden',marginLeft:isLargeScreen?"":"20px"}}>
      <h2 className=" mb-2">Calma Calma: Cristiano Ronaldo Strikes Again!</h2>
      <h4 className="mb-0 text-white">
      A brilliant moment of composure as Ronaldo scores a decisive goal to lead his team to victory
      </h4>
    </div>

    <div
      className="position-relative"
      style={{
        width: '300px',
        height: '500px',
      }}
    >

      <img
        src={Phone}
        alt="Phone Frame"
        className="position-absolute w-100 h-100"
        style={{ zIndex: 10, objectFit: 'contain' }}
      />

      <video
        src={VideoSrc}
        className="position-absolute"
        style={{
          top: '3.1%',
          left: '12.3%',
          width: '75%',
          height: '94.1%',
          zIndex: 5,
          objectFit: 'cover',
        }}
        autoPlay
        loop
        muted
      />
    </div>
  </div>
  


  )
}

export default Section2