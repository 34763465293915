import { configureStore } from '@reduxjs/toolkit';
import storeReducer from './redux/storeSlice';
import userReducer from './redux/userSlice';
import { apiSlice } from './redux/api/apiSlice';

const store = configureStore({
  reducer: {
    store: storeReducer,
    user: userReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;