import React, { useEffect, useRef } from 'react';
import 'owl.carousel'; // Import Owl Carousel
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { motion } from "framer-motion";
import php from '../../assets/php.png';
import Laravel from '../../assets/laravel.png';
import ReactJS from '../../assets/react.png';
import Next from '../../assets/next.png';
import Node from '../../assets/node.png';
import Redis from '../../assets/redis.png';
import MySQL from '../../assets/mysql.png';
import MongoDB from '../../assets/mongodb.png';
import Ubuntu from '../../assets/ubuntu.png';
import Flutter from '../../assets/flutter.png';

const Section4 = () => {
    const [t, i18n] = useTranslation("global");

    const owlCarouselRef = useRef<HTMLDivElement>(null);

    const dir = Cookies.get('direction') || 'ltr';

    const rtl = (dir == 'rtl');

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            // y: 200
            x:200
        },
        animate: {
            opacity: 1,
            // y: 0,
            x:0,
            transition: { duration: 1, delay: 0.2 }
        }
    }

    useEffect(() => {
        let initialized = false;

        const initializeCarousel = () => {
            $(owlCarouselRef.current!).owlCarousel({
                margin: 30,
                loop: true,
                autoplay: true,
                autoplayTimeout: initialized ? 3000 : 0, // Set initial autoplayTimeout to 0
                smartSpeed: 3000,
                touchDrag: false,
                mouseDrag: false,
                rtl: rtl,
                nav: false,
                dots: false,
                items: 6,
                responsive: {
                    0: {
                        items: 2
                    },
                    600: {
                        items: 2
                    },
                    1000: {
                        items: 5
                    }
                }
            });

            // Set initialized to true after first initialization
            initialized = true;

            // Update autoplayTimeout after the first rotation
            $(owlCarouselRef.current!).on('changed.owl.carousel', function(event) {
                if (!initialized) return;
                $(owlCarouselRef.current!).trigger('stop.owl.autoplay');
                $(owlCarouselRef.current!).trigger('play.owl.autoplay', [3000]);
            });
        };

        initializeCarousel();

        // Cleanup on unmount
        return () => {
            $(owlCarouselRef.current!).trigger('destroy.owl.carousel');
        };
    }, [rtl]);
    
    return (
        <motion.div variants={fadeInAnimationVariants} initial="initial" whileInView={fadeInAnimationVariants.animate} viewport={{once:true}} className="container-fluid bg-white py-5">
             <div className="container-fluid bg-white">
        <div className="row container-xl mx-auto section4 justify-content-center">
          <div className="col-xl-2 col-md-3 mb-4 mb-md-0 text-center text-md-start d-flex align-items-center">
            <h3 className="fs-5 m-0 text-dark fw-semibold">{t("home.section4.title")}</h3>
          </div>
          <div ref={owlCarouselRef} className="owl-carousel owl-theme col-xl-10 col-md-9 d-flex justify-content-center">
            <div className="item">
              <img src={ReactJS} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Ishtari" />
            </div>
            <div className="item">
              <img src={MySQL} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Ishtari Energy" />
            </div>
            <div className="item">
              <img src={php} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="English" />
            </div>
            <div className="item">
              <img src={Laravel} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Flo" />
            </div>
            <div className="item">
              <img src={Node} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Kokonano" />
            </div>
            <div className="item">
              <img src={Next} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Zymta" />
            </div>
            <div className="item">
              <img src={MongoDB} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Snipextc" />
            </div>
            <div className="item">
              <img src={Redis} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Snipextc" />
            </div>
            <div className="item">
              <img src={Ubuntu} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Snipextc" />
            </div>
            <div className="item">
              <img src={Flutter} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Snipextc" />
            </div>
          </div>
        </div>
      
        <style>{`
          .section4 .left h3 {
            line-height: 1.3em;
            color: #272657;
            font-weight: 600;
          }
          .owl-carousel .item {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .owl-carousel .item img {
            height: 80px;
            width: auto;
            object-fit: contain;
          }
          .owl-carousel {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </div>
      
        </motion.div>
    )
}

export default Section4