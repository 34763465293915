import React, { useEffect, useRef } from 'react';
import 'owl.carousel'; 
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import English from '../../assets/english.png';
import Flo from '../../assets/flo.png';
import Ishtari from '../../assets/ishtari.png';
import Zymta from '../../assets/zymta.png';
import Snipextc from '../../assets/snipextc.png';
import Kokonano from '../../assets/kokonano.png';
import IshtariEnergy from '../../assets/ishtarienergy.png';

const Section2 = () => {

    const [t, i18n] = useTranslation("global");

    const dir = Cookies.get('direction') || 'ltr';

    const rtl = (dir == 'rtl');

    const owlCarouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let initialized = false;

        const initializeCarousel = () => {
            $(owlCarouselRef.current!).owlCarousel({
                margin: 30,
                loop: true,
                autoplay: true,
                autoplayTimeout: initialized ? 3000 : 0, // Set initial autoplayTimeout to 0
                smartSpeed: 3000,
                touchDrag: false,
                mouseDrag: false,
                rtl: rtl,
                nav: false,
                dots: false,
                items: 6,
                responsive: {
                    0: {
                        items: 2
                    },
                    600: {
                        items: 2
                    },
                    1000: {
                        items: 5
                    }
                }
            });

            // Set initialized to true after first initialization
            initialized = true;

            // Update autoplayTimeout after the first rotation
            $(owlCarouselRef.current!).on('changed.owl.carousel', function(event) {
                if (!initialized) return;
                $(owlCarouselRef.current!).trigger('stop.owl.autoplay');
                $(owlCarouselRef.current!).trigger('play.owl.autoplay', [3000]);
            });
        };

        initializeCarousel();

        // Cleanup on unmount
        return () => {
            $(owlCarouselRef.current!).trigger('destroy.owl.carousel');
        };
    }, [rtl]);

    return (
        <div className="container-fluid bg-white py-5">
        <div className="row container-xl mx-auto section2 justify-content-center">
          <div className="col-xl-2 col-md-3 mb-4 mb-md-0 text-center text-md-start d-flex align-items-center">
            <h3 className="fs-5 m-0 text-dark fw-semibold">{t("home.section2.title")}</h3>
          </div>
          <div ref={owlCarouselRef} className="owl-carousel owl-theme col-xl-10 col-md-9 d-flex justify-content-center">
            <div className="item">
              <img src={Ishtari} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Ishtari" />
            </div>
            <div className="item">
              <img src={IshtariEnergy} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Ishtari Energy" />
            </div>
            <div className="item">
              <img src={English} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="English" />
            </div>
            <div className="item">
              <img src={Flo} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Flo" />
            </div>
            <div className="item">
              <img src={Kokonano} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Kokonano" />
            </div>
            <div className="item">
              <img src={Zymta} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Zymta" />
            </div>
            <div className="item">
              <img src={Snipextc} className="d-block img-fluid" style={{ height: '80px', width: 'auto', objectFit: 'contain' }} alt="Snipextc" />
            </div>
          </div>
        </div>
      
        <style>{`
          .section2 .left h3 {
            line-height: 1.3em;
            color: #272657;
            font-weight: 600;
          }
          .owl-carousel .item {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .owl-carousel .item img {
            height: 80px;
            width: auto;
            object-fit: contain;
          }
          .owl-carousel {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </div>
      
      
      
      
    )
}

export default Section2