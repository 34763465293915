import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useTransition } from 'react';
// import 'bootstrap-select/dist/js/bootstrap-select.min.js';
// import '../../src/js/bootstrap-select.min.js';
import $ from 'jquery';
import { Form, Dropdown } from 'react-bootstrap';
import Cookies from 'js-cookie';


const Header = () => {
  const location = useLocation();
  const [isAtTop, setIsAtTop] = useState(true);

  // Check if the current route is home
  const isHomeRoute = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      // Check if the scroll position is at the top
      setIsAtTop(window.scrollY === 0);
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

//   useEffect(() => {
//     $("#selectpicker").selectpicker("refresh");
// }, []);


const savedLanguage = Cookies.get('language') || 'en';
const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage);

  const handleSelect = (eventKey:any) => {
    i18n.changeLanguage(eventKey);
    Cookies.set('language', eventKey, { expires: 365 });
    // i18n.dir('rtl');
    if(eventKey == 'ar'){
      document.body.dir = 'rtl';
      Cookies.set('direction', 'rtl', { expires: 365 });
    } else{
      document.body.dir = 'ltr';
      Cookies.set('direction', 'ltr', { expires: 365 });
    }
    setSelectedLanguage(eventKey);
  };

  const [t, i18n] = useTranslation("global");
  

  return (
    <div className={`container-fluid navbar-light sticky-top border-opacity-50 ${isHomeRoute && isAtTop ? '' : 'bg-white border-bottom'}`}>
      <nav className="navbar navbar-expand-xl">
        <header className="container-xl py-1">
          <div>
            <NavLink className="navbar-brand d-flex" to="/"><img src="/logo.png" alt="Cloudgoup" className="light-mode-item navbar-brand-item img-fluid logo" /></NavLink>
          </div>
          <div>
            <div>
              <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" className="navbar-toggler ms-auto outline-0" >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" className="offcanvas offcanvas-end h-100 bg-white">
              <div className="offcanvas-header">
                <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" className="btn-close" ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} aria-current="page" to="/">{t("header.home")}</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/about" >About Us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/portfolio" >Porfolio</NavLink>
                  </li>
                 
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/services" >Our Services</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/contact" >Let's Talk</NavLink>
                  </li>
                  {/* <li className="nav-item d-flex align-items-center">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle variant="" id="lang-select">
                      {selectedLanguage === 'en' ? '🇺🇸 English' : '🇸🇦 Arabic'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="en">🇺🇸 English</Dropdown.Item>
                      <Dropdown.Item eventKey="ar">🇸🇦 Arabic</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  </li> */}
                  {/* <li className="nav-item ms-1">
                    <NavLink className="nav-link btn-purple" aria-current="page" to="/contactus" >Contact Us</NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </header>
      </nav>
    </div>
  )
}

export default Header